

// import VirtueLogo from "../../images/virtue-logo.svg";
// import "./style.css";



// {/* Virtue Developer section */}
// <div className="container pt-3 pt-md-0 py-md-5">
// <div className="row">
//   <div className="col-12 col-md-4 px-5 px-md-auto">
//     <figure className="d-flex justify-content-start justify-content-md-center mb-0">
//       <img className="virtue-logo-lg me-4" src={VirtueLogo} alt="logo" />
//     </figure>
//   </div>
//   <div className="d-flex flex-column col-12 col-md-8 pt-3 pt-md-0 px-0 ps-md-5 px-5 px-md-auto">
//     <h2 className="fw-semibold mb-3">Virtue: Crafting Homes with Integrity</h2>
//     <p className="heart-sub-text">
//       At Virtue, we pride ourselves on a legacy of excellence in the real estate industry. With decades of experience and a portfolio of successful projects, we are committed to delivering homes that exceed expectations. Our unwavering dedication to quality, innovation, and timely delivery has earned us the trust and satisfaction of countless homeowners.
//     </p>
//     <p className="heart-sub-text">

//       Every project we undertake is a testament to our core values of integrity, craftsmanship, and customer-centricity. At Virtue, we don't just build homes; we create communities where families thrive and dreams come to life. Welcome to a world where excellence is our standard and your satisfaction is our promise.
//     </p>
//     <button className="d-flex align-items-center btn-transparent btn-gradient-outline p-0" title="View Infra Website" type="button" >Virtue Infra Website
//       <span className="custom-arrow custom-right"></span>
//       <span className="custom-arrow custom-right"></span>
//     </button>

//   </div>
// </div>
// </div>

import React from "react";
import VirtueLogo from "../images/virtue-logo.svg";
import "./SliderSection/style.css";

const VirtueDeveloperSection = () => {
  return (
    <div className="container pt-3 pt-md-0 py-md-5">
      <div className="row">
        {/* Logo Section */}
        <div className="col-12 col-md-4 px-5 px-md-auto">
          <figure className="d-flex justify-content-start justify-content-md-center mb-0">
            <img className="virtue-logo-lg me-4" src={VirtueLogo} alt="Virtue Logo" />
          </figure>
        </div>

        {/* Text Section */}
        <div className="d-flex flex-column col-12 col-md-8 pt-3 pt-md-0 px-0 ps-md-5 px-5 px-md-auto">
          <h2 className="fw-semibold mb-3">Virtue: Crafting Homes with Integrity</h2>
          <p className="heart-sub-text">
            At Virtue, we pride ourselves on a legacy of excellence in the real estate industry. With decades of experience and a portfolio of successful projects, we are committed to delivering homes that exceed expectations. Our unwavering dedication to quality, innovation, and timely delivery has earned us the trust and satisfaction of countless homeowners.
          </p>
          <p className="heart-sub-text">
            Every project we undertake is a testament to our core values of integrity, craftsmanship, and customer-centricity. At Virtue, we don't just build homes; we create communities where families thrive and dreams come to life. Welcome to a world where excellence is our standard and your satisfaction is our promise.
          </p>

          {/* Button Section */}
          <button
            className="d-flex align-items-center btn-transparent btn-gradient-outline p-0"
            title="View Infra Website"
            type="button"
          >
            {/* Virtue Infra Website */}
            {/* <span className="custom-arrow custom-right"></span> */}
            {/* <span className="custom-arrow custom-right"></span> */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VirtueDeveloperSection;
