import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MdOutlineLocationOn,
  MdOutlineMail,
  MdOutlinePhone,
  MdContactPhone
} from "react-icons/md";
import { Form } from "react-bootstrap";
import { TbWorldWww } from "react-icons/tb";
import { toast } from "react-toastify";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import AboutIcon from "../../images/about-icon.png";
import AspireIcon from "../../images/aspire logo black.png";
import VirtueLogo from "../../images/virtue-logo.svg";
import "./style.css";

const Footer = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    name: "",
    phone: "",
    message: "",
  });
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (value.length < 9) {
      toast.error("Please Enter a valid Phone Number");
      return;
    }
    setLoading(true);
    try {
      const data = {
        email: form.email,
        name: form.name,
        phone: value,
        message: form.message,
      };
      const response = await axios.post(
        "https://api.aspireprop.com/cn/addSohanEnquiry",
        data
      );
      console.log("Enquiry submitted:", response.data);
      setForm({
        email: "",
        name: "",
        phone: "",
        message: "",
      });
      setValue();
      navigate("/thank-you");
    } catch (err) {
      alert("Please Try Again");
    }
    setLoading(false);
  };

  return (

    <>

      {/* Main footer section */}

      <div className="mt-5 cd-flex align-items-center justify-content-center flex-column section-15" style={{ backgroundColor: "#f7f7f7" }}
      >
        <div className="footer container" style={{ paddingBottom: "45px" }}>
          <div className="px-3 col-12 d-flex flex-wrap pb-3 pt-0 pt-md-5">
            <div className="col-12 col-md-8  d-flex flex-wrap">
              {/* Column 1 */}
              <div className="col-12 col-sm-6 col-md-6 px-2">
                <div className="d-flex p-0 px-1 mx-0 me-md-4">
                  <img className="footer-logo me-4" src={VirtueLogo} alt="logo" />
                </div>
                <div className="d-flex p-0">
                  <div className="me-2">
                    <MdOutlineLocationOn />
                  </div>
                  <div>
                    <p>
                      Sy No. 178/15, 178/16, 178/17,<br />
                      178/18, New Water Tank,<br />
                      Kannamangala, <br />
                      Bengaluru - 560 017
                    </p>
                  </div>
                </div>
                {/* <div className=" d-flex p-0">
                  <div className="me-2">
                    <MdOutlineMail />
                  </div>
                  <div>
                    <p>hello@aspireprop.com</p>
                  </div>
                </div> */}
                <div className=" d-flex p-0">
                  <div className="me-2">
                    <MdOutlinePhone />
                  </div>
                  <div>

                    <a
                      style={{
                        textDecoration: "none",
                        display: "block",
                        color: "#000",
                      }}
                      target="_blank"
                      href="tel:9148448256"
                      rel="noreferrer"
                    >
                      <p className="mb-0">+91 9148448256</p>
                    </a>
                    <a
                      style={{ textDecoration: "none", color: "#000", display: "block" }}
                      href="tel:9513319002"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="mb-0">+91 9513319002</p>
                    </a>
                  </div>
                </div>
                <div className="d-flex pt-3">
                  <div className="me-2">
                    <MdContactPhone />
                  </div>
                  <div>
                    <p>
                       PRM/KA/RERA/1251/446/PR/300924/007100
                    </p>
                  </div>
                </div>
              </div>
              {/* Column 2 */}
              <div className="col-12 col-sm-6 col-md-6 px-2">
              <br /> 

                <div className="d-flex p-0 px-1 mx-0 me-md-4">
                  

                  <img
                    className="footer-logo me-4"
                    src={AspireIcon}
                    alt="logo"
                    style={{ transform: "scale(0.7)", maxWidth: "100%", height: "auto" }}
                  />
                </div>
                <div className="d-flex p-0 ps-1">
                  <div className="me-2">
                    <MdOutlineLocationOn />
                  </div>
                  <div>
                    <p>
                    NO. 45/11, First Floor, North Park <br />
Road, Kumara Park East,<br/>
Bengaluru 560 001
                    </p>
                  </div>
                </div>
                <div className=" d-flex p-0 ps-1">
                  <div className="me-2">
                    <MdOutlineMail />
                  </div>
                  <div>
                    <p>hello@aspireprop.com</p>
                  </div>
                </div>

                <div className=" d-flex pt-3">
                  <div className="me-2">
                    <MdOutlineMail />
                  </div>
                  <div>
                    <a href="https://aspireprop.com" className="text-decoration-none" style={{ color: "#151b54" }}>
                      www.aspireprop.com
                    </a>
                  </div>
                </div>
              </div>
              {/* Column 3
               */}
              <div className="col-12 d-flex d-md-none flex-column d-md-flex px-2">
                <div className="footer-logo">
                  <h6 className="yellow-title">Enquiry Form</h6>
                </div>
                <Form onSubmit={handleFormSubmit}>
                  <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label>Your Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={handleFormChange}
                      value={form.email}
                      required
                      placeholder="Enter Your Email"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="formBasicPassword">
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                      required
                      name="name"
                      onChange={handleFormChange}
                      value={form.name}
                      type="text"
                      placeholder="Enter Your Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="formBasicPassword">
                    <PhoneInput
                      className="input w-100 phone-input"
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      country={"in"}
                      value={value}
                      onChange={(e) => setValue(e)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-0" controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      name="message"
                      onChange={handleFormChange}
                      value={form.message}
                      required
                      placeholder="Enter Your Query"
                    />
                  </Form.Group>

                  <button
                    disabled={loading}
                    className="btn btn-outline-warning col-12 col-lg-4 my-4"
                    type="submit"
                  >
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </Form>
              </div>
              <div className="col-12 mt-4 px-2">
                <h3 className="fw-semibold text-orange">Disclaimer</h3>
                <p className="text-md fw-semibold">
                  The content mentioned in this website is for information purposes only, prices mentioned are subject te Change without notice & properties mentioned are subject to availability. Images are for representation purposes only. This is not the official website. This website belongs to the official channel partner of Virtue infracon. We may also send updates to the mobile email id registered with us. {" "}
                </p>
                <p className="fw-semibold">All Rights Reserved.</p>
              </div>
            </div>
            <div className="col-4 mt-3 d-none d-md-flex flex-column px-2">
              <div className="d-flex align-items-center" style={{ height: "100px" }}>
                <h5 className="fw-semibold text-orange pb-3">Have a query?</h5>
              </div>
              <Form onSubmit={handleFormSubmit}>

                <label htmlFor="email" className="form-label">Your Email</label>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleFormChange}
                    value={form.email}
                    required
                    placeholder="Enter Your Email"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <label htmlFor="email" className="form-label">Your Name</label>
                  <Form.Control
                    required
                    name="name"
                    onChange={handleFormChange}
                    value={form.name}
                    type="text"
                    placeholder="Enter Your Name"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <label htmlFor="phoneNumber" className="form-label">Your Phone Number</label>
                  <PhoneInput
                    className="input w-100 phone-input"
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    country={"in"}
                    value={value}
                    onChange={(e) => setValue(e)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <label htmlFor="query" className="form-label">Your Query</label>
                  <Form.Control
                    type="text"
                    name="message"
                    onChange={handleFormChange}
                    value={form.message}
                    required
                    placeholder="Enter Your Query"
                  />
                </Form.Group>

                <button
                  style={{ width: "150px" }}
                  disabled={loading}
                  className="border-warning btn-outline-gradient btn-border-radius px-4 py-2"
                  type="submit"
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </Form>
            </div>
          </div>
          <hr className="hr-footer-color" />
          <div className="d-flex justify-content-between px-5 py-3">
            <div className="col-6 col-sm-4">
              <p>Copyright ©️ 2024</p>
            </div>
            <Link
              to={"/privacy-policy"}
              style={{ textDecoration: "none", color: "#ffffff" }}
              className="col-6 col-sm-4 text-center"
            >
              <h6 className="text-dark text-underline">Privacy Policy</h6>
            </Link>
            <div className="col-0  col-sm-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
