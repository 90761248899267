import React from "react";
import Area from "../images/WarriorSection/area.png";
import Squarefit from "../images/WarriorSection/sqarefit.png";
import Units from "../images/WarriorSection/unit.png";
import Bhk from "../images/WarriorSection/bhk.png";
import Image1 from "../images/WarriorSection/warrior-img.png";
import FlowerIcon from "../images/flower-icon.png";
import Building from "../images/view-8-1.png";
import AerialView from "../images/hero-section-right.png";
const imagePaths = {
  bhk: Bhk,
  area: Area,
  units: Units,
  squarefit: Squarefit,
};


const WelcomWarriorSection = ({ setModalShow }) => {
  const handleClick = () => {
    setModalShow(true);
  };
  return (
    <>
      <div id="" className="px-0 container pt-4 main-warrior-section">
        {/* Laptop and Desktop View */}
        <div class="row warrior-main-div pb-0 pb-md-5 m-0">

          <div class="col-12 col-xl-8 p-0 warrior-right-side-container">
            <div className="mt-0 mt-md-5 ps-4 ps-md-0">
              <div className="warrior-header">Designed for Life,</div>
              <div className="warrior-header">Built for You</div>
            </div>
            <div className="container px-0">
              <div className="warrior-sub-text pt-2 pb-4 ps-4 ps-md-0 ">
                Experience the Harmony of Luxury and Serenity
              </div>

              <div className="d-flex flex-column flex-column-reverse flex-md-row pe-lg-5 pt-0 pt-md-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <section className="d-flex flex-wrap d-md-block pt-5 pb-4 ps-4 ps-md-0">
                    <div className="d-flex align-items-end pb-2 me-3 me-md-0">
                      <div className="d-flex align-items-center">
                        <img src={FlowerIcon} alt="Icon" />
                        <h1 className="fw-semibold units-heading mx-2 mb-0" style={{ fontSize: '2.2rem' }}>2&3 BHK</h1>

                      </div>
                      {/* <div className="units-measure">Acres</div> */}

                    </div>
<br></br>
                    <div className="d-flex align-items-end pb-2">
                      <div className="d-flex align-items-center">
                        <img src={FlowerIcon} alt="Icon" />
                        <h1 className="fw-semibold units-heading mx-2 mb-0" style={{ fontSize: '2.2rem' }}>40</h1>

                      </div>  <div className="units-measure fw-semibold">units</div>
                    </div>


                    <div className="d-flex align-items-end pb-2 pt-3">
                      <div className="d-flex align-items-center">
                        <img src={FlowerIcon} alt="Icon" />
                        <h1 className="fw-semibold units-heading mx-2 mb-0" style={{ fontSize: '2.2rem' }}>Kannamangala, Whitefield</h1>
                        {/* <h3 className=" fw-semibold mx-2 mb-0"> Kannamangala, Whitefield</h3> */}
                      </div>
                    </div>
                    <button className="btn-gradient px-5 mx-md-auto mx-lg-0 mt-3" type="button" onClick={handleClick}>
                      Explore Nirvana           
                    </button>
                  </section>
                </div>
                <div className="col-12 col-md-6 col-lg-8 d-flex justify-content-end">
                  <div className="building-img">

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-none d-xl-block col-md-4 p-0">
            <img src={AerialView} alt="bedroom-1" className="bedroom-img-1 pe-3" style={{ height: "70vh" }} />
          </div>
        </div>

      </div>
      {/* Section 2 of elevate your life at nirvana */}
      <div id="overview">
      <section className="container py-5">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="warrior-header1 line-height-sm fw-500">Elevate Your <br/>Life at Nirvana</div>
            <div className="warrior-img-container mx-auto">
              {Object.keys(imagePaths).map((key) => (
                <img
                  key={key}
                  src={imagePaths[key]}
                  alt={key}
                  className="warrior-img"
                />
              ))}
            </div>
          </div>
          <div className="d-flex flex-column col-12 col-lg-6 px-3 px-md-0">
            <p className="heart-sub-text">
              Step into a world of sophistication and comfort at <span class = "fw-semibold">Nirvana</span>. Located in the rapidly growing residential locale of <span class = "fw-semibold">Kannamangala, Whitefield, Nirvana</span> offers 40 meticulously crafted 2 and 3 BHK homes. Each unit is a design masterpiece, featuring expansive layouts and high-end finishes.
            </p>
            <p className="heart-sub-text">
              At <span class = "fw-semibold">Nirvana</span>, experience the pinnacle of luxury with <span class = "fw-semibold">contemporary architecture, modern amenities</span> and <span class = "fw-semibold"> vastu- compliant homes </span>. Enjoy the privacy of <span clss = "fw-semibold" > no-shared walls</span>  and <span class = "fw-semibold" >super spacious </span> homes with the <span class = "fw-semibold" >highest carpet area </span> and <span class = "fw-semibold" >UDS in the segment</span>. Crafted with<span class = "fw-semibold" > global brands</span>  and<span class = "fw-semibold" > superior finishes</span> , <span class = "fw-semibold" > Nirvana</span> guarantees quality construction and timely delivery.
            </p>
            <p className="heart-sub-text">
            <span class = "fw-semibold" > Nirvana</span> is your gateway to a life of unparalleled luxury and convenience.
            </p>
          </div>
        </div>
      </section>
      </div>
    </>
  );
};

export default WelcomWarriorSection;
